<template>
  <div class="setting_form h-full">
    <div class="w-full h-full bg-[#F7F7FC]">
      <div
        class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px]"
      >
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <!-- Header -->
            <div class="flex px-3 py-[1rem] border-b h-[4.188rem] justify-between items-center">
              <div class="w-full flex gap-x-2">
              </div>
              <div class="w-full flex justify-center items-center">
                <p class="text font-bold text-center !text-[1.125rem] pr-[0.2rem] !text-[#3C4549]">Other Settings</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
                    fill="#3C4549"
                  />
                </svg>
              </div>
              <div class="w-full flex items-center justify-end space-x-2"></div>
            </div>
            <table class="links_table text-left w-full">
              <thead
                class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins bg-[#F4F6FA]"
              >
                <tr>
                  <td class="py-3 pl-3 w-1/2">Permissions</td>
                  <td class="py-3 pr-3 text-right w-1/2">Actions</td>
                </tr>
              </thead>


              <tbody  class="font-poppins text-[#3C4549] text-[0.875rem]">
                <tr
                  class="border-b border-[#ECEEF5] px-3"
                >
                <td class="py-4 text !text-[14px] pl-3 w-1/2"> Append original link to the URL of the shortened {{ getSiteDetails.agency_name }} link.</td>
                <td class="py-4 pr-3 text-right w-1/2">
                 <SwitchButton :status="!getProfile.append_hash"
                 @click="getProfile.append_hash = !getProfile.append_hash;updateMiscSetting('append_hash')" ></SwitchButton>

                </td>
              </tr>
              <tr
                  v-if="settingsAllowed()"
                  class="border-b border-[#ECEEF5] px-3"
                >
                <td class="py-4 text !text-[14px] pl-3 w-1/2"> Show "Powered by Replug" on your branded messages and Biolinks</td>
                <td class="py-4 pr-3 text-right w-1/2">
                 <SwitchButton :status="!getProfile.poweredBy"
                 @click="getProfile.poweredBy = !getProfile.poweredBy;updateMiscSetting('poweredBy')" ></SwitchButton>

                </td>
              </tr>

              <tr

                  class="border-b border-[#ECEEF5] px-3"
                >
                <td class="py-4 text !text-[14px] pl-3 w-1/2">Transfer UTM Parameters to the Destination URL.</td>
                <td class="py-4 pr-3 text-right w-1/2">
                 <SwitchButton v-if="allowDisablingBranding()" :status="!getProfile.transfer_utm"
                 @click="getProfile.transfer_utm = !getProfile.transfer_utm;updateMiscSetting('transfer_utm')" ></SwitchButton>
                 <SwitchButton v-else :status="!getProfile.transfer_utm"
                 @click.prevent="getProfile.transfer_utm = !getProfile.transfer_utm;showUpgradeModal()" ></SwitchButton>

                </td>
              </tr>


              <tr

                  class="border-b border-[#ECEEF5] px-3"
                >
                <td class="py-4 text !text-[14px] pl-3 w-1/2">Show summary page as a fallback if destination URL has iframe issue.</td>
                <td class="py-4  pr-3 text-right w-1/2">
                 <SwitchButton :status="!getProfile.fallback_cta"

                 @click="getProfile.fallback_cta = !getProfile.fallback_cta;updateMiscSetting('fallback_cta')" ></SwitchButton>

                </td>
              </tr>


              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ROLES } from '@/common/constants'
import SwitchButton from "@/ui/ui-kit/v2/SwitchButton.vue";


export default ({
  components:{
    SwitchButton,
  },
  computed: {
    ...mapGetters(['getProfile', 'getSubscription', 'getWhiteLabel'])
  },
  methods: {
    ...mapActions([]),
    settingsAllowed () {
      return this.getProfile && [ROLES.super_admin, ROLES.admin].includes(this.getProfile.role) && !this.isWhiteLabelSetup()
    },
    allowDisablingBranding () {
      return this.getSubscription && this.getSubscription.limits.branding !== false
    }
  }

})
</script>
